<template>
    <transition name="expand" tag="div" @enter="enter" @after-enter="afterEnter" @leave="leave">
        <slot />
    </transition>
</template>

<script>
export default {
    name: 'TransitionExpand',
    methods: {
        enter(element) {
            const width = getComputedStyle(element).width;

            element.style.width = width;
            element.style.position = 'absolute';
            element.style.visibility = 'hidden';
            element.style.height = 'auto';

            const height = getComputedStyle(element).height;

            element.style.width = null;
            element.style.position = null;
            element.style.visibility = null;
            element.style.height = 0;

            setTimeout(() => {
                element.style.height = height;
            });
        },
        afterEnter(element) {
            element.style.height = 'auto';
        },
        leave(element) {
            const height = getComputedStyle(element).height;
            element.style.height = height;

            setTimeout(() => {
                element.style.height = 0;
            });
        },
    },
};
</script>

<style lang="scss" scoped>
* {
    will-change: height;
    backface-visibility: hidden;
    // transform: translateZ(0); // FIX UISearchDropdown - Full page bug on mobile
    // perspective: 1000px;
}

.expand-enter-active,
.expand-leave-active {
    transition: height 0.5s ease-in-out, opacity 0.5s ease-in-out;
    overflow: hidden;
}

.expand-enter,
.expand-leave-to {
    height: 0;
    opacity: 0;
}
</style>
