<template>
    <div class="scrolling-modal">
        <article>
            <h1>{{ translate('title') }}</h1>
            <p>{{ translate('message') }}</p>
            <form ref="loginForm" novalidate @submit.stop.prevent>
                <label for="email" class="font-weight-bold">{{ translate('emailLabel') }}</label>
                <span class="required ml-1">({{ translate('required') }})</span>
                <UIFormInput
                    id="email"
                    v-model="email"
                    class="mb-3"
                    type="email"
                    :placeholder="translate('emailPlaceholder')"
                    required
                    validation="email"
                    @keyup.enter.native="login"
                >
                    <template #error-message>
                        {{ translate('email.error') }}
                    </template>
                    <template #warning-message>
                        {{ translate('email.warning') }}
                    </template>
                </UIFormInput>
                <UIButton ref="login" variant="primary" type="submit" class="w-100" required @click.native="login">{{
                    translate('button')
                }}</UIButton>
            </form>

            <div class="or-divider">
                <span>{{ translate('separator') }}</span>
            </div>
            <UIButton variant="google" class="w-100">
                <nuxt-resource src="images/icons/account-google.svg" alt="Google Logo" aria-hidden="true" />
                {{ translate('googleButton') }}
            </UIButton>

            <TransitionExpand>
                <div v-if="expandedOptions" id="loginOptions" class="collapse">
                    <UIButton variant="facebook" class="w-100 mt-2">
                        <nuxt-resource src="images/icons/account-facebook.svg" alt="Facebook Logo" aria-hidden="true" />
                        {{ translate('facebookButton') }}
                    </UIButton>
                    <UIButton variant="twitter" class="w-100 mt-2">
                        <nuxt-resource src="images/icons/account-twitter.svg" alt="Twitter Logo" aria-hidden="true" />
                        {{ translate('twitterButton') }}
                    </UIButton>
                </div>
            </TransitionExpand>
            <div
                class="link show-more d-block text-center mt-2"
                data-toggle="collapse"
                role="button"
                :aria-expanded="expandedOptions"
                aria-controls="loginOptions"
                @click="expandLoginOptions"
            >
                {{ showOptionsText }}
            </div>
        </article>
    </div>
</template>

<script>
import { UIFormInput, UIButton } from '@dundle/ui/components';
import { mapActions } from 'vuex';
import TransitionExpand from '~/components/transition/TransitionExpand.vue';
import TranslationMixin from '~/mixins/TranslationMixin';

export default {
    components: {
        UIButton,
        UIFormInput,
        TransitionExpand,
    },
    mixins: [TranslationMixin],

    data() {
        return {
            email: this.$auth.loggedIn && this.$auth.strategy.name === 'guest' ? this.$auth.user.email : '',
            expandedOptions: false,
            error: false,
        };
    },

    computed: {
        showOptionsText() {
            return this.expandedOptions ? this.translate('showLessOptions') : this.translate('showMoreOptions');
        },
    },

    methods: {
        ...mapActions({
            showModal: 'ui/showModal',
            hideModal: 'ui/hideModal',
        }),

        expandLoginOptions() {
            this.expandedOptions = !this.expandedOptions;
        },

        async login() {
            if (this.$validation.validateForm(this.$refs.loginForm)) {
                if (this.$refs.login) this.$refs.login.startLoading();

                try {
                    await this.$user.login('guest', this.email);
                    await this.$user.requestVerificationEmail(this.email);

                    this.showModal({ name: 'verify', canClickAway: false });
                    this.hideModal();
                } finally {
                    if (this.$refs.login) this.$refs.login.stopLoading();
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 768px) {
    .login-card {
        padding-left: 40px;
        padding-right: 40px;
    }
}

.facebook,
.google,
.twitter {
    position: relative;

    img {
        position: absolute;
        left: 0.7rem;
        max-height: 28px;
    }
}

.or-divider {
    font-weight: bold;
    color: $color-grey-3;
    position: relative;
    text-align: center;
    margin: 1.5rem 0;

    span {
        background: $color-bg;
        padding: 0 0.3rem;
        position: relative;
        z-index: 2;
    }

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0.7rem;
        left: 0;
        background: $color-grey-3;
        height: 1px;
        width: 100%;
    }
}
</style>

<i18n src="@dundle/locale/data/validation.json"></i18n>
<i18n>
// not yet in CMS
{
    "en": {
        "title": "Sign in / Sign up",
        "message": "Enter your emailaddress. We will sent you an email to verify your login.",
        "emailLabel": "Email address",
        "button": "Login",
        "separator": "OR",
        "googleButton": "Sign in with Google",
        "facebookButton": "Sign in with Facebook",
        "twitterButton": "Sign in with Twitter",
        "showMoreOptions": "Show more options",
        "showLessOptions": "Show less options",
		"emailPlaceholder": "Enter email address"
	}
}
</i18n>
